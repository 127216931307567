<template>
    <v-container>
        <v-layout row justify-center>
            <v-dialog
                    v-model="dialog"
                    max-width="350"
            >
                <v-card>
                    <v-card-title class="headline" v-text="`${delsta.DDATE}, ${delsta.TTIME}, ${delsta.STATYPE}`"/>
                    <v-spacer></v-spacer>
                    <v-card-subtitle v-text="`${delsta.MED}`"/>
                    <v-card-text v-text="`${delsta.INFO}`"/>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="primary"
                                @click="dialog = false"
                        >
                            Обратно
                        </v-btn>

                        <v-btn
                                color="error"
                                @click="dialogDelStaDo"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>


        <v-row>

            <v-col
                    cols="12"
                    sm="6"
            >
                <v-card
                        class="pa-2"
                        elevation="0"
                >
                    <!--v-card-title></1--v-card-title-->
                    <h2>{{header}}</h2>
                    <v-alert type="error"
                             close-text="Закрыть сообщение"
                             dismissible
                             v-model="alertError.show"
                             @click="navBack()"
                    >
                        {{alertError.text}}
                    </v-alert>
                    <v-alert type="warning"
                             close-text="Закрыть сообщение"
                             dismissible
                             v-model="alertWarning.show"
                             @click="navBack()"
                    >
                        {{alertWarning.text}}
                    </v-alert>
                    <v-btn v-if="navBackShow" block
                           color="primary"
                           @click.prevent="navBack()"
                           elevation="10"
                    >
                        Обратно
                    </v-btn>
                    <v-card v-if="!navBackShow"
                            class="mt-6 pa-2"
                            elevation="10"
                            outlined
                            :loading="loading"
                    >
                        <v-card-title v-text="`${cursta.DDATE}, ${cursta.TTIME}, ${cursta.STATYPE} `"/>
                        <v-card-subtitle v-text="`${cursta.MED}`"/>
                        <v-card-text v-text="`${cursta.INFO}`"/>

                        <v-card-title  v-text="`Ждем вас, ${KARD.p_f} ${KARD.p_i} ${KARD.p_o}`"/>
                      <v-card-text> <b>Для получения талона подойдите в регистратуру за 15 мин до приема. При себе иметь паспорт, полис ОМС.</b></v-card-text>
                        <!--v-card-title v-html="`${KARD.p_f} ${KARD.p_i} ${KARD.p_o} <br> Ждем вас ${STA.ddate} с ${STA.timeb} по ${STA.timee}`"/-->
                        <!--v-card-title v-if="!navBackShow" v-text="`Ждем вас ${STA.ddate} с ${STA.timeb} по ${STA.timee}`"/-->
                        <!--v-card-subtitle v-text="`Код талона ${STA.nppsta}`"/-->

                        <!--v-card-text>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title v-text="`${KARD.p_f} ${KARD.p_i} ${KARD.p_o}`"/>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="!navBackShow">
                                <v-list-item-content>
                                    <v-list-item-title v-text="`Ждем вас ${STA.ddate} с ${STA.timeb} по ${STA.timee}`"/>
                                    <v-list-item-subtitle v-text="`Код талона ${STA.nppsta}`"/>
                                </v-list-item-content>
                            </v-list-item>
                                       class="mb-6 ml-3"
                                       class="mb-6 ml-3"

                        </v-card-text-->
                        <v-card-actions>
                            <!--v-container fluid-->
                                <v-row

                                        justify="space-between"
                                        class="mb-2 mr-2"
                                >
                                <v-btn color="primary"
                                       class="ml-4"
                                       @click.prevent="onOk"
                                       :loading="loadingBtn"
                                >
                                    Хорошо
                                    <!--v-icon
                                            right
                                            dark
                                    >
                                        mdi-thumb-up-outline
                                    </v-icon-->

                                </v-btn>

                                <!--v-btn class="ml-4"
                                       @click.prevent="onBad"
                                       :loading="loadingBtn"
                                >
                                    Плохо
                                    <v-icon
                                            right
                                            dark
                                    >
                                        mdi-thumb-down-outline
                                    </v-icon>

                                </v-btn-->


                                <v-btn small
                                       text
                                       color="error"
                                       @click.prevent="dialogDelSta(cursta,-1)"
                                >
                                    Удалить
                                </v-btn>
                                </v-row>

                            <!--/v-container-->
                        </v-card-actions>
                    </v-card>
                </v-card>

                <v-card v-if="arrsta.length>0"
                        class="pa-2 mt-5"
                        elevation="0"
                >
                    <h2>Список выписанных талонов</h2>
                    <v-card
                            class="mt-6 pa-2"
                            elevation="10"
                            outlined
                            v-model="arrsta"
                            v-for="(e,i) in arrsta"
                            :key="e.NPPSTA"
                    >
                        <v-card-title v-text="`${e.DDATE}, ${e.TTIME}, ${e.STATYPE}`"/>
                        <v-card-subtitle v-text="`${e.MED}`"/>
                        <v-card-text v-text="`${e.INFO}`"/>
                        <v-card-actions>
                            <v-row
                                   justify="end"
                                   class="mb-2 mr-2"
                            >
                                <v-btn small
                                       text
                                       color="error"
                                       @click.prevent="dialogDelSta(e,i)"
                                >
                                    Удалить
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-card>

            </v-col>

            <v-col
                    cols="12"
                    sm="6"
            >
                <v-card
                        class="pa-2"
                        elevation="0"
                >
                    <h2>{{HEADER_CURSTA}}</h2>
                    <v-card
                            class="mt-6 pa-2"
                            :class="{disabled: CANCELED}"
                            elevation="0"
                            outlined
                    >


                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-html="LPU.name"/>
                                <v-list-item-subtitle v-text="LPU.address"/>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="FIN.name"/>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="AGE.name"/>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="MEDG.PANELCAPTION1"/>
                                <v-list-item-subtitle v-text="MEDG.PANELCAPTION2"/>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="MED.NNAME"/>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="DDATE.DDATE"/>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="TTIME.NNAME"/>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="STA && STA.nppsta">
                            <v-list-item-content>
                                <v-list-item-subtitle v-text="`Код талона ${STA.nppsta}`"/>
                            </v-list-item-content>
                        </v-list-item>


                    </v-card>
                </v-card>
            </v-col>

        </v-row>
    </v-container>

</template>>

<style>
    .disabled {
        pointer-events: none;
        text-decoration: line-through;
        color: grey;
    }

</style>


<script>
    import post, {postLog} from '../tools/post'
    import {toBack,  toNext,  thisLog, curstaEmpty} from '@/components/app/js/globals'
    import {mdiThumbUpOutline, mdiThumbDownOutline} from '@mdi/js';

    export default {
        name: 'Sta',
        data: () => ({
            name: 'Sta',
            header: 'Талон посещения',
            dialog: false,
            loading: false,
            loadingBtn: false,
            log: true,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            delsta: {}, //{}данные по удаленному талону посещения из списка талонов DDATE, TTIME, MED, INFO
            delstai: null, //-1 текущий, иначе индекс в arrsta
            cursta: {}, //{} curstaFillEmpty()данные по заведенному талону посещения из списка талонов DDATE, TTIME, MED, INFO
            arrsta: {} //{}
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            FIN() {
                return this.$store.getters.FIN;
            },
            AGE() {
                return this.$store.getters.AGE;
            },
            MEDG() {
                return this.$store.getters.MEDG;
            },
            MED() {
                return this.$store.getters.MED;
            },
            DDATE() {
                return this.$store.getters.DDATE;
            },
            TTIME() {
                return this.$store.getters.TTIME;
            },
            KARD() {
                return this.$store.getters.KARD;
            },
            STA() {
                return this.$store.getters.STA;
            },
            CANCELED() {
                return (this.cursta.NPPSTA?false:true);
            },
            HEADER_CURSTA() {
                return (this.cursta.NPPSTA?'Выбрано':'Отменено');
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                    (this.$store.getters.FIN.actual && this.$store.getters.FIN.actual==='1') &&
                    (this.$store.getters.AGE.actual && this.$store.getters.AGE.actual==='1') &&
                    (this.$store.getters.MEDG.actual && this.$store.getters.MEDG.actual==='1') &&
                    (this.$store.getters.MED.actual && this.$store.getters.MED.actual==='1') &&
                    (this.$store.getters.DDATE.actual && this.$store.getters.DDATE.actual==='1') &&
                    (this.$store.getters.TTIME.actual && this.$store.getters.TTIME.actual==='1') &&
                    (this.$store.getters.KARD.actual && this.$store.getters.KARD.actual==='1')
            },
            navBackShow() {
                return (this.alertError.show || this.alertWarning.show)
            }
        },
        async beforeMount() {
            this.cursta=curstaEmpty()
            this.$store.dispatch('SET_STA', [])
            //console.log('----',this.is_actual)
            if(this.is_actual) {
                //--------------  новый талон
                let url, data, newNppSta
                url = this.$store.getters.LPU.resturl + '/getsta'
                thisLog(this, ['url', url])
                data = {
                    dd: this.KARD.p_dd,
                    mm: this.KARD.p_mm,
                    yyyy: this.KARD.p_yyyy,
                    f: this.KARD.p_f,
                    i: this.KARD.p_i,
                    o: this.KARD.p_o,
                    opt: this.KARD.p_opt,
                    nppmed: this.MED.NPP,
                    ddate: this.DDATE.DDATE,
                    timeb: this.TTIME.TB,
                    timee: this.TTIME.TE,
                    kardonhands: (this.KARD.p_kardonhands ? '1' : '0'),
                    tel: this.KARD.p_tel,
                    email: this.KARD.p_email,
                    rem: '',
                    nppkard_noreq: this.KARD.p_nppkard,
                    NppSpec_noreq: ''
                }
                thisLog(this, ['DDATE---', this.DDATE, this.DDATE.DDATE])
                thisLog(this, ['TTIME---', this.TTIME])
                try {
                    let res = await post(url, data)
                    thisLog(this, [` STA >>>>>>>>> `, res]) //NPPSTA, MSG, ERR
                    if (res && res.data && res.data.length == 1) { //ответ получен
                        const el = res.data[0]
                        if (el.NPPSTA && el.NPPSTA.length > 0) {
                            if (el.MSG && el.MSG.length > 0) {
                                this.alertWarning.text = el.MSG
                                this.alertWarning.show = true
                                //pushNote(this, el.MSG, {msg: true})
                            }
                            data.nppsta = el.NPPSTA
                            newNppSta=data.nppsta //чтобы убрать из списка выписанных талонов
                            thisLog(this, [data])
                            this.$store.dispatch('SET_STA', data)
                            thisLog(this, ['STA DATA ===', data])
                            //                            this.'Талон успешно заведен'
                            this.$store.dispatch('SET_DDATE_ACTUAL', '0') //занято - обновить
                            //pushNext(this)
                        } else {
                            do {
                                if (el.ERR && el.ERR.length > 0) {
                                    this.alertError.text = el.ERR
                                    this.alertError.show = true
                                    break; //---
                                }
                                if (el.MSG && el.MSG.length > 0) {
                                    this.alertWarning.text = el.MSG
                                    this.alertWarning.show = true
                                    break; //---
                                }
                                if(!(this.alertError.show || this.alertError.show)) {
                                    this.alertError.text = "Ошибка заведения талона"
                                    this.alertError.show = true
                                }
                            } while (false)
                        }
                    } else {
                        this.alertWarning.text = "Что-то пошло не так"
                        this.alertWarning.show = true
                        //this.navBack()  //каскадный откат
                    }

                } catch (err) {
                    //console.log('sta1',err)
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Stag#1' } )
                    //alert(err)
                }
                //--------------  список талонов
                url = this.$store.getters.LPU.resturl + '/getstalist'
                thisLog(this, ['url', url])
                data = {
                    nppkard: this.KARD.p_nppkard,
                    opt: this.KARD.p_opt
                }
                thisLog(this, ['nppkard---', this.KARD.p_nppkard])
                thisLog(this, ['opt---', this.KARD.p_opt])
                try {
                    let res = await post(url, data)
                    thisLog(this, [` STALIST >>>>>>>>> `, res]) //NPPSTA, MSG, ERR
                    thisLog(this, [` STALIST >>>>>>>>> `, res]) //NPPSTA, MSG, ERR
                    if (res && res.data) { //ответ получен
                        let i=res.data.findIndex( e => e.NPPSTA==newNppSta)
                        if(i!==-1) {
                            this.cursta = res.data.splice(i, 1)[0]
                        }
                        this.arrsta = res.data
                    }
                } catch (err) {
                    //console.log('sta2',err)
                    //alert(err)
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Sta#2' } )
                }

            }
            this.loading=false
            //console.log(this.cursta)

        },
        mounted() {
            if(this.is_actual) {
                //window.scrollTo(0, top)
            }else{
                this.alertWarning.text = "Что-то пошло не так"
                this.alertWarning.show = true
                this.navBack()  //каскадный откат÷
            }
        },
        methods: {
            async navBack(){
                this.drawer=false
                //console.log('navback',this)
                toBack(this,{prev:true, noerr:true, nomsg:true})
            },
            async onOk(){
                //console.log(this.cursta.NPPSTA,this.cursta.NPPKARD,'Ok')
                await this.$router.push('/')
            },
            async onBad(){
                //console.log(this.cursta.NPPSTA,'Bad')
                await this.$router.push('/')
            },
            async onDelSta(){

                this.alertWarning.text = `Отмена талона от ${this.STA.ddate} с ${this.STA.timeb} по ${this.STA.timee}, ${this.FIN.name} к ${this.MED.NNAME}...`
                this.alertWarning.show = true
                this.loading=true
                if(this.is_actual && this.STA.nppkard_noreq && this.STA.nppsta) {
                    if(await this.delSta(this.STA.nppsta,this.STA.nppkard_noreq)){
                        alert('Удалено!')
                    }
                }
                this.loading=false
                await this.$router.push('/')

            },
            dialogDelSta(el, i){
                this.delsta=el
                this.delstai=i
                this.dialog=true
            },
            dialogDelStaDo(){
                if(this.delSta(this.delsta.NPPSTA, this.delsta.NPPKARD)){
                    this.delArrSta(this.delstai)
                }
                this.dialog=false
            },
            async delArrSta(i){
                if(i==-1) {
                    this.cursta=curstaEmpty()//                 this.cursta=[]
                    this.alertWarning.text = `Отмена талона от ${this.STA.ddate} с ${this.STA.timeb} по ${this.STA.timee}, ${this.FIN.name} к ${this.MED.NNAME}...`
                    this.alertWarning.show = true
                    this.$store.dispatch('SET_STA', []) //после записи сообщения!!!
                    window.scrollTo(0, top)
                    thisLog(this,['...',this.cursta.NPPSTA,(this.cursta.NPPSTA?'ДА':'НЕТ')])
                }else{
                    if (await this.delSta(this.arrsta[i].NPPSTA, this.arrsta[i].NPPKARD)) {
                        this.arrsta.splice(i, 1)
                    }
                }
            },
            async delSta(p_nppsta, p_nppkard){
                let ret=false

                const url = this.$store.getters.LPU.resturl + '/delsta'
                thisLog(this,['url', url])
                let data = {
                    nppsta:                   p_nppsta,
                    nppkard:                  p_nppkard
                }
                thisLog(this,['nppsta---',data.nppsta])
                thisLog(this,['nppkard---',data.nppkard])
                try {
                    let res = await post(url, data)
                    thisLog(this,[` DELSTA >>>>>>>>> `, res]) //NPPSTA, MSG, ERR
                    if (res && res.data && res.data.length == 1) { //ответ получен
                        const el = res.data[0]
                        if(el.ERR===null){
                            ret=true
                        } else {
                            this.alertError.text = el.ERR
                            this.alertError.show = true
                        }
                    } else {
                        this.alertWarning.text = 'Что-то пошло не так'
                        this.alertWarning.show = true
                    }

                } catch (err) {
                    //alert(err)
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Sta#3' } )
                }
                return ret;
            }
        }
    }
</script>
